//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import scroll from '@/mixins/scroll'

export default {
  name: 'HintSection',
  mixins: [scroll],
}
